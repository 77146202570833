import React, { Component } from "react";
import Helmet from "react-helmet";
import { useLocation } from 'react-router-dom'

class SEO extends Component {
  render() {
    let title;
    let description;
    let image;

    title = useLocation.pathname;
    
    return (

      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
      </Helmet>
      )
  };
}



export default SEO;