import React from 'react';
import LiIcon from '../Icons/LinkedIn64.png';
import ReactGA from 'react-ga4';


export default function LinkedInBadge() {


  const sendOutbound = () => {
    ReactGA.send('event', 'Link', 'Click', 'LinkedIn')
  };

  return (

    <div id='social-content'>
      <a class="social-link" href="https://www.linkedin.com/company/94156632/" onClick={sendOutbound}  target="_blank" data-type="li">    
          <img src={LiIcon} alt="AJG Software LinkedIn" style={{width:'20px'}}/>   
      </a>
    </div>
  );
}


