import React from 'react'
import NavBar from './NavBar';
import SEO from '../components/SEO'

class About extends React.Component {
    render() {
      return (
      <>

<SEO />
<NavBar />
<div className="container-text">
<h2>About</h2>

<p>About.</p>

</div>   
      </>
      )
    }
  }

  export default About;

