import React from 'react';
import Padding from './Padding';
import axios from 'axios';
import Popup from 'react-popup';
import ReCAPTCHA from "react-google-recaptcha";

class ContactForm extends React.Component {
    constructor(props) {
      super(props);
      this.myRef = React.createRef();
    }

    render() {

      const verifyToken = async (token) => {
        try {
          let response = await axios.post(`https://ajgsoftware.com/api/verify-token`, {
            secret: process.env.REACT_APP_SECRET_KEY,
            token
          }, console.log("post verify"))


          return response.data;
          
        } 
        catch (error) {
          
        }
      }


      const handleSubmit = async (e) => {
        e.preventDefault();

        let token = this.myRef.current.getValue();
        this.myRef.current.reset();

        if (token) {
          let valid_token = await verifyToken(token);

          if (valid_token.success) {
            var na = e.target.elements.name.value.trim();
            var em = e.target.elements.email.value.trim();
            var re = e.target.elements.request.value.trim();

    
            if (na!== "" && em !== "" && re !== "")
            {
              //Remove any carriage returns.
              na = na.replace("\n","");
              na = na.replace("\r","");
              em = em.replace("\n","");
              em = em.replace("\r","");
              re = re.replace("\n","");
              re = re.replace("\r","");

              //check for any suspicous messages
              if(na.includes("cc:") || em.includes("cc:") || re.includes("cc:"))
              {
                Popup.alert("Invalid entry.");
                return;
              }
              if(na.includes("content-type:") || em.includes("content-type:") || re.includes("content-type:"))
              {
                Popup.alert("Invalid entry.");
                return;
              }
    
            const options = {
                 method: "post",
                 data: {
                   name: na,
                   email: em,
                   request: re
                 },
                 url: 'https://ajgsoftware.com/api/request',
               };
    
              axios(options)
               .then((response) => {
                  Popup.alert('Your request has been received and we will get back to you as soon as possible.');
                  e.target.elements.name.value = "";
                  e.target.elements.email.value = "";
                  e.target.elements.request.value = "";
               }, (error) => {
                  Popup.alert('There has been an issue, please email sales@ajgsoftware.com with your request');
                  e.target.elements.name.value = "";
                  e.target.elements.email.value = "";
                });
              
              }
              else
              {
                Popup.alert('Please make sure all fields have been filled in.');
              }

          } else {
            Popup.alert('Invalid token.');
          }

        } else {
          Popup.alert("Please confirm you are not a robot.");
        }

 

    }

      return (

      <div id="Contact" style={{backgroundColor: '#151520'}}>

        <Padding/>
        <Padding/>
        <center>

        <div>

          <div className="contact-form-container" style={{width:"50%"}}>

            <h2 className="section-title">Have a concept in mind? <br/> Let us make it a reality.</h2>

            <form className="contact-form" onSubmit={handleSubmit}>
                <label htmlFor="name" style={{textAlign:"left"}}>full name</label>
                <input defaultValue="" id="name" type ="name" placeholder="name" name="name" />

                <label htmlFor="email" style={{textAlign:"left"}}>email</label>
                <input defaultValue="" id="email" type ="email" placeholder="youremail@gmail.com" name="email" />

                <label htmlFor="request" style={{textAlign:"left"}}>request</label>
                <textarea name="content" id="request" placeholder="Tell us about it" rows="10"></textarea>
                <Padding/>
                <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={this.myRef}/>
                <Padding/>
                <button type="submit">Send your request</button>
            </form>
          </div>


          
        </div> 
        </center>
          <Padding/>
          <Padding/>
      </div> 
  
      )
    }
  }

export default ContactForm;

