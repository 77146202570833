import './App.css';
import './popup.css';
import MyRoutes from "./components/router";
import Footer from "./content/Footer";
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-PDFHZSY9Z6"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
        <>
          <MyRoutes />
          <Footer />
        </>
  );
}

export default App;
