import React from "react"
import logo from '../Icons/AJGSoftwareW32.png';

function ActionLink(props) {

    const handleClick = (e) => {

      var elm = document.getElementById(props.link);
    
      if (elm != null) {
          elm.scrollIntoView({ behavior: 'smooth', inline: 'start' });   
          console.log("elm: " + elm);
      }
      else {
          console.log("elm is null");
          return;
      }


      e.preventDefault();
    }
  
    return (

      <a href={props.link} onClick={handleClick}>
        {props.description}
      </a>
    );
  }

export const NavBar = (props) => {

    return (
        <>

        <div className={`nav-class`} >
            <header id="nav-content" className="App-header" >
                <div id="nav-row" className='row'>
                    <div id="nav-links" className='column'><ActionLink link="" description={<img src={logo} alt='AJG Software'/>}></ActionLink></div>
                    <div id="nav-links" className='column'><ActionLink link="OurServices" description="Our Services"/></div>
                    <div id="nav-links" className='column'><ActionLink link="SoftwareDevelopment" description="Software Development"/></div>
                    <div id="nav-links" className='column'><ActionLink link="RoboticsAutomation" description="Robotics & Automation"/></div>
                    <div id="nav-links" className='column'><ActionLink link="Contact" description="Contact"/></div>
                </div>
            </header>
        </div> 

        </>
    )
}

export default NavBar;
