import React from 'react'
import logo from '../Icons/softwaredevelopment.png';
import Fade from 'react-reveal/Fade';
import Padding from './Padding';

class SoftwareDevelopment extends React.Component {
    render() {
      return (
        

      <div id='SoftwareDevelopment' style={{backgroundColor: '#050515'}}>

        <Padding/>
        <Padding/>
        <div className="container-text" style={{padding:"30px"}}>
            <h2>Software Development</h2>
            <div> 
              <div className="float-left"> 
                <Fade left duration={1000} fraction="0.5">
                  <img src={logo} style={{maxWidth:'400px',padding:"20px"}} alt="AJG Software Office Laptop" />
                </Fade>
              </div>
              <div style={{padding:"10px"}}> 
              <p>Our software consultancy company is a leading provider of software solutions for industry. We specialize in developing software applications for both Windows and Linux platforms, and our experts have a wealth of experience in designing, building, and deploying customised software solutions.</p>
              <p>If you have legacy software this can also be upgraded and enhanced, ensuring it meets current industry requirements and the latest technologies. Additionally, we excel in taking charge of projects that may have been left unfinished, providing a dedicated approach to completion.</p>
              <p>We understand the unique challenges faced by industries such as manufacturing, consumer goods, healthcare and logistics. Our software solutions are designed to help our clients achieve their business goals while maintaining compliance with industry standards.</p>
              <p>Our team has a deep understanding of the latest technologies and trends in the industry, including machine learning, computer vision, and robotics. We leverage this expertise to design and develop software solutions that enable our clients to automate their processes, increase efficiency, and reduce costs.</p>
              <p>We take a collaborative approach by working closely with clients to understand their unique needs and requirements, and then develop tailored solutions that meet their specific needs. We also provide ongoing support and maintenance to ensure that our software solutions continue to meet our clients' needs as their business evolves.</p>
              <p>If you are looking for a software consultancy company we would be happy to discuss your project and see how we can help.</p>
              </div>
           </div>
        </div>

        <Padding/>
        <Padding/>
      </div>
  
      )
    }
  }

export default SoftwareDevelopment;

