import React from 'react'
import Popup from 'react-popup';
import NavBar from './NavBar';
import SEO from '../components/SEO'
import Revolution from './Revolution';
import Sales from './Sales';
import SoftwareDevelopment from './SoftwareDevelopment';
import RoboticsAutomation from './RoboticsAutomation';
import OurServices from './OurServices';
import ContactForm from './ContactForm';

class Main extends React.Component {

    render() {
      return (

      <>
        <Popup />

        <NavBar/>

        <Revolution/>

        <Sales/>

        <OurServices/>

        <SoftwareDevelopment/>

        <RoboticsAutomation/>

        <ContactForm/>

        <Sales/>
        
        <SEO/>
      </>
      )
    }
  }

  export default Main;
