import React, {useEffect } from 'react';
//import Main from "../content/MainHolding";
import Main from "../content/Main";
import SoftwareDevelopment from "../content/SoftwareDevelopment";
import RoboticsAutomation from '../content/RoboticsAutomation';
import ContactForm from '../content/ContactForm';
import OurServices from '../content/OurServices';
import About from '../content/About';
import NotFound from "../content/NotFound";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-PDFHZSY9Z6"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);


const MyRoutes = () => {

  useEffect(() => {
    ReactGA.send("pageview");//.(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/SoftwareDevelopment" element={<SoftwareDevelopment />} />
          <Route exact path="/RoboticsAutomation" element={<RoboticsAutomation />} />
          <Route exact path="/OurServices" element={<OurServices />} />
          <Route exact path="/Contact" element={<ContactForm />} />
          <Route exact path="/About" element={<About />} />
          <Route path='*' element={<NotFound />} status={404} />
        </Routes>
      </Router>
    </>
  );
};

export default MyRoutes;
