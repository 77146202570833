import React from "react";

const Footer = () => {
  return (
    <div className='row' id="copyright-content">
      <div className='column'>
        <p>© 2024 AJG Software Ltd</p>
      </div>
    </div>
  );
};

export default Footer;
