import React from 'react'

import logo from '../Icons/FileNotFoundG.png';

class NotFound extends React.Component {
    render() {
      return (
      <>


  <div className='row' >
     
      <div className='column' id='notfound-container'>
        <h1>404</h1>
        <h2>Not Found</h2>
        <a href='https://www.ajgsoftware.com'>Take me home</a>
      </div>

      <div className='column' >
        <img src={logo} alt="File Not Found" />
      </div>

      </div>
 
      </>
      )
    }
  }

  export default NotFound;

