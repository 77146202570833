import React from 'react'
import Padding from './Padding';
import Fade from 'react-reveal/Fade';

class OurServices extends React.Component {
    render() {
        return (


            <div id="OurServices" style={{ backgroundColor: "#000010" }}>

                <Padding />
                <Padding />

                <div className="container-text">
                    <h2>Our Services</h2>
                    <div id="services-content" style={{ padding: '10px' }}>

                        <p></p>

                        <div className='services-row' >
                            <div className='column'>
                                <p>We are committed to meeting the needs of our customers. Employing modern technology we craft innovative, scalable, and efficient software. If you have a specific interest in a particular technology let us know!</p>
                            </div>
                        </div>
                        <div className='services-row' >

                            <div className='services-column'>
                                <Fade >
                                    <h3>Custom Software Development</h3>
                                    <p>Designing and developing specialised bespoke software solutions. Deploying the technology most suitable for the requirements.</p>
                                </Fade>
                            </div>

                            <div className='services-column'>
                                <Fade>
                                    <h3>Control System Integration</h3>
                                    <p>Integrating custom software with a variety of control systems, including PLCs and other automation hardware. Including development of custom device drivers.</p>
                                </Fade>
                            </div>

                            <div className='services-column'>
                                <Fade>
                                    <h3>Database and LIMS</h3>
                                    <p>We offer customised solutions that enhance data management, accessibility, and analytical capabilities for our clients. </p>
                                </Fade>
                            </div>

                            <div className='services-column'>
                                <Fade>
                                    <h3>Vision</h3>
                                    <p>Creating vision applications ranging from basic vision sensors to comprehensive multi-camera vision systems utilising the latest technologies in AI and Machine Learning.</p>
                                </Fade>
                            </div>

                            <div className='services-column'>
                                <Fade>
                                    <h3>IoT</h3>
                                    <p>Producing custom software to enable the development of IoT-connected devices and Cloud services.</p>
                                </Fade>
                            </div>

                            <div className='services-column'>
                                <Fade>
                                    <h3>Robotics Programming</h3>
                                    <p>Writing and optimising code to control and coordinate robot movements with other systems, ensuring precision and efficiency.</p>
                                </Fade>
                            </div>

                            <div className='services-column'>
                                <Fade>
                                    <h3>Human-Machine Interface (HMI)</h3>
                                    <p>Creating intuitive and user-friendly interfaces that allow operators to interact with and monitor automated systems.</p>
                                </Fade>
                            </div>

                            {/* <div className='services-column'>
                        <Fade>
                        <h3>Simulation and Testing</h3>
                        <p>Conducting thorough simulation and testing of the software to validate its performance and identify any potential issues before deployment.</p>
                        </Fade>
                    </div> */}

                            {/* <div className='services-column'>
                        <Fade>
                        <h3>Real-time Monitoring and Reporting</h3>
                        <p>Implementing features for real-time monitoring of automation processes and generating detailed reports for performance analysis and optimization.</p>
                        </Fade>
                    </div> */}
                            {/*     
                    <div className='services-column'>
                        <Fade>
                        <h3>Safety Systems Integration</h3>
                        <p>Incorporating safety protocols and systems within the software to ensure compliance with industry standards and regulations.</p>
                        </Fade>
                    </div> */}
                            <div className='services-column'>
                                <Fade>
                                    <h3>Maintenance and Support</h3>
                                    <p>Providing ongoing maintenance and support services to address issues, apply updates, and ensure the continued reliability of a system.</p>
                                </Fade>
                            </div>
                            <div className='services-column'>
                                <Fade>
                                    <h3>Training</h3>
                                    <p>Offering training programs and comprehensive documentation to empower client teams.</p>
                                </Fade>
                            </div>


                        </div>

                    </div>
                </div>

                <Padding />
                <Padding />
            </div>

        )
    }
}

export default OurServices;

