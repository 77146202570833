
import React from 'react'

class Padding extends React.Component {
    render() {
      return (
        <div className='row' style={{padding: '30px 10%'}}>
        </div>
      )
    }
  }

  export default Padding;


