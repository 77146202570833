import React from 'react';
import Background from '../Icons/ajgbackgroundc.png';
import Zoom from 'react-reveal/Zoom';
import Padding from './Padding';

class Revolution extends React.Component {
    constructor(props) {
      super(props)
      this.myRef = React.createRef()
      this.state = {scrollTop: 0}
    }

    render() {

        return (

      <>

      <Padding/>
      <div id="full-panel">

      <div className='row' style={{padding: '10vh'}}>  
        </div>

      <Zoom>

      <div id="holding" style={{backgroundImage:`url(${Background})`}}> 

        <div className='row'>
          <div className='column'>
            <div className='b-column'>
        
              <div id='strapline' className='row'>
              <h2>Revolutionising industries with cutting-edge software solutions</h2>
              </div>

              <div className='row' style={{height: '100px'}}>
              <p>We are a software consultancy located in Cheshire England offering consultancy, design and development services.</p>
              </div>
            </div>
          </div>
          <div className='column'>
            <div className='g-column'>
            </div>
          </div>
        </div>
      </div>
      </Zoom>
      </div>
      </>
      )
    }
  }

  export default Revolution;
