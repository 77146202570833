import React from 'react'
import robot from '../Icons/robot.png';
import Padding from './Padding';
import Fade from 'react-reveal/Fade';


class RoboticsAutomation extends React.Component {
    render() {
      return (
        <div  id="RoboticsAutomation" style={{backgroundColor: '#101015'}}>


          <Padding/>
          <Padding/>


            <div className="container-text" style={{padding:"30px"}}>
              
            <h2>Robotics and Automation</h2>

            <div className="float-right">
              <Fade right duration={2000} fraction="0.5"> 
                <img src={robot} style={{maxWidth:'400px'}} alt="AJG Software Robots" />
              </Fade>
            </div>
              <div style={{padding:"10px"}}> 


            <p>Specialising in bespoke software development for controlling automation hardware offering a suite of services tailored to the unique needs of clients.</p>

            <p>Our consultancy services can identify opportunities for automation. This can then be followed by design, prototyping and deployment of tailored solutions that optimise processes and enhance productivity</p>

            <p>Software can be built to control and manage diverse automation hardware and robotic systems. The software undergoes simulation and testing phases to validate performance and identify any potential issues before deployment. Monitoring and reporting features allowing for detailed analysis and optimisation.</p>

            <p>The systems will be easily upgradable as the client's needs evolve by design. The company also provides comprehensive documentation and training programs.</p>

            <p>Beyond the initial development and implementation, we offer ongoing maintenance and support services to address any issues, apply updates, and ensure the continued reliability of any automation software.</p>

            </div> 
  
            </div> 
          {/* <p>The company stays at the forefront of innovation, offering consultation on emerging technologies in the automation and robotics field to ensure that clients remain ahead in their respective industries.</p> */}

 {/* <p>These services encompass the complete lifecycle, starting with in-depth consultation to understand the specific requirements. Development of comprehensive design documentation which is then used to implment the software control system.</p> */}

         {/* <p>Safety is a paramount consideration, and the company ensures that the software incorporates robust safety protocols and systems to comply with industry standards and regulations.</p> */}


          <Padding/>
          <Padding/>
        </div> 

      )
    }
  }

  export default RoboticsAutomation;

