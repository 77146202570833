import React from 'react'
import LinkedInBadge from '../components/linkedinbadge'
import Padding from './Padding';

class Sales extends React.Component {


    render() {

      return (
      <>

      <Padding/>

      <div className='row' id="contact-content">
      <div className='column'>
        </div>
        <div className='column'>
          <p>For sales or general enquiries please contact
          <a href="mailto:sales@ajgsoftware.com" className="Email">  sales@ajgsoftware.com</a></p>
          <p id="telephone-content">Telephone: +44 (0)1244 911 021</p>
        </div>
        <div className='column' id='social-content' style={{width:'64px'}}>
          <LinkedInBadge />
        </div>

      </div>


      </>
      )
    }
  }

  export default Sales;
